import { configureStore } from '@reduxjs/toolkit'
import { createStore, compose, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { authReducer } from './reducers/authReducers'
import { usersReducer } from './reducers/usersReducer';
import { stateReducer } from './reducers/stateReducer';
import { infoReducer } from './reducers/infoReducer';
import { settingReducer } from "./reducers/settingsReducer"

const rootReducer = combineReducers({
    auth: authReducer,
    users: usersReducer,
    state: stateReducer,
    infos: infoReducer,
    settings: settingReducer
});

const middleware = [thunkMiddleware]

const store = configureStore({ reducer: rootReducer });

export default store;