export const SETTINGS_GET_SUCCESS = 'SETTINGS_GET_SUCCESS'
export const SETTINGS_GET_FAIL = 'SETTINGS_GET_FAIL'
export const SETTINGS_CREATE_SUCCESS = 'SETTINGS_CREATE_SUCCESS'
export const SETTINGS_CREATE_FAIL = 'SETTINGS_CREATE_FAIL'


export const SETTINGS_SUCCESS_MESSAGE_CLEAR = 'SETTINGS_SUCCESS_MESSAGE_CLEAR'
export const SETTINGS_ERROR_CLEAR = 'SETTINGS_ERROR_CLEAR'


