export const REGISTER_FAIL = 'REGISTER_FAIL'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const SUCCESS_MESSAGE_CLEAR = 'SUCCESS_MESSAGE_CLEAR'
export const ERROR_CLEAR = 'ERROR_CLEAR'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'


export const INSTALL_SUCCESS = 'INSTALL_SUCCESS'
export const INSTALL_FAIL = 'INSTALL_FAIL'

export const ADMIN_QRCODE_SUCCESS = 'ADMIN_QRCODE_SUCCESS'
export const ADMIN_QRCODE_FAIL = 'ADMIN_QRCODE_FAIL'

export const ADMIN_VERIFYQRCODE_SUCCESS = 'ADMIN_VERIFYQRCODE_SUCCESS'
export const ADMIN_VERIFYQRCODE_FAIL = 'ADMIN_VERIFYQRCODE_FAIL'

export const ADMIN_VERIFYQRCODE_SUCCESS_CLEAR = 'ADMIN_VERIFYQRCODE_SUCCESS_CLEAR'

export const USER_GET_META_SUCCESS = 'USER_GET_META_SUCCESS'


export const USER_LOGIN_VERIFY_SUCCESS = 'USER_LOGIN_VERIFY_SUCCESS'
export const LOGIN_VERIFY_FAIL = 'LOGIN_VERIFY_FAIL'


export const DBURL_SUCCESS = 'DBURL_SUCCESS'
export const DBURL_FAIL = 'DBURL_FAIL'


export const ADMIN_DATA_GET_SUCCESS = 'ADMIN_DATA_GET_SUCCESS'
export const ADMIN_DATA_GET_FAIL = 'ADMIN_DATA_GET_FAIL'

export const ADMIN_DATA_UPDATE_SUCCESS = 'ADMIN_DATA_UPDATE_SUCCESS'
export const ADMIN_DATA_UPDATE_FAIL = 'ADMIN_DATA_UPDATE_FAIL'


export const SIGN_OUT = 'SIGN_OUT'


