import React, { Component, Suspense, useEffect } from 'react'
import { HashRouter, BrowserRouter, Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import './scss/style.scss'
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
   // after calling LogRocket.init()
   LogRocket.init('i4jcug/mytpt-app', {
    dom: {
      inputSanitizer: true,
    },
  });

  setupLogRocketReact(LogRocket);

  /* useEffect(() => {
    console.log("here",window.location.pathname);
    if (localStorage.getItem('currentUser') && window.location.pathname !== "/base/adduser") {
      console.log("here")
      localStorage.removeItem('currentUser');
      // localStorage.clear();
    }
  }, [window.location.pathname]) */

  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}
export default App