import {USER_GET_SUCCESS,  USER_DELETE_SUCCESS, USER_DELETE_FAIL, USER_UPDATE_SUCCESS, 
    USER_UPDATE_FAIL, USER_SUCCESS_MESSAGE_CLEAR, USER_ERROR_CLEAR, USER_META_GET_SUCCESS} from '../types/userType.js'

const userState = {
    userData : [],
    userAllData: [],
    UserData: {},
    userError : '',
    userSuccessMessage: '',
    userSuccessMessagee: ''
}

export const usersReducer = (state = userState , action)=>{
    const {type, payload} = action
    // const {users, successMessage } = payload
    // console.log(payload.userData)
    
    if(type === USER_GET_SUCCESS){
        return{
            ...state,
            userData : payload.userData,
            userSuccessMessage: payload.userSuccessMessage
        }
    }
    if(type === USER_DELETE_SUCCESS){
        return{
            ...state,
            userSuccessMessagee : payload.successMessage,
        }
    }
    if(type === USER_UPDATE_SUCCESS){
        return{
            ...state,
            userSuccessMessagee : payload.successMessage
        }
    }
    if(type === USER_DELETE_FAIL){
        return{
            ...state,
            userError :  payload.error
        }
    }
    if(type === USER_UPDATE_FAIL){
        return{
            ...state,
            userError :  payload.error
        }
    }
    if(type === USER_META_GET_SUCCESS){
        return {
            ...state,
            userAllData: payload.userAllData,
            UserData: payload.UserData
        }
    }
    if(type === USER_SUCCESS_MESSAGE_CLEAR){
        return{
            ...state,
            userSuccessMessagee :  ""
        }
    }
    if(type === USER_ERROR_CLEAR){
        return{
            ...state,
            userError :  ""
        }
    }

    return state;
}