
import {SIDE_MENU, TOP_MENU} from '../../stores/types/stateType'



const fontState = {
    sidebarShow: true,
    unfoldable: true,
}



export const stateReducer = (state = fontState, action) => {
    const { payload, type } = action;
    if(type === SIDE_MENU){
        return {
          ...state,
          unfoldable: !payload.curState
        }
    }
    if(type === TOP_MENU){
        return {
          ...state,
          sidebarShow: !payload.curTopState
        }
    }
    
    return state
}