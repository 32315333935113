export const USER_GET_SUCCESS = 'USER_GET_SUCCESS'
export const USER_GET_FAIL = 'USER_GET_FAIL'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'


export const USER_SUCCESS_MESSAGE_CLEAR = 'USER_SUCCESS_MESSAGE_CLEAR'
export const USER_ERROR_CLEAR = 'USER_ERROR_CLEAR'
export const USER_META_GET_SUCCESS = 'USER_META_GET_SUCCESS'

export const SET_OTP = 'SET_OTP'