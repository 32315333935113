import {
    REGISTER_FAIL, REGISTER_SUCCESS, SUCCESS_MESSAGE_CLEAR, ERROR_CLEAR, LOGIN_FAIL,
    USER_LOGIN_SUCCESS, SIGN_OUT, ADMIN_QRCODE_SUCCESS, ADMIN_QRCODE_FAIL, ADMIN_VERIFYQRCODE_SUCCESS,
    ADMIN_VERIFYQRCODE_FAIL, ADMIN_VERIFYQRCODE_SUCCESS_CLEAR, USER_GET_META_SUCCESS, USER_LOGIN_VERIFY_SUCCESS,
    LOGIN_VERIFY_FAIL, ADMIN_DATA_GET_SUCCESS, ADMIN_DATA_GET_FAIL, ADMIN_DATA_UPDATE_SUCCESS, ADMIN_DATA_UPDATE_FAIL
} from '../types/authType'
import deCodeToken from 'jwt-decode'

const authState = {
    loading: true,
    authenticate: false,
    totpVerifyMessage: '',
    secret: '',
    qrCode: '',
    error: '',
    successMessage: '',
    successVerifyMessage: '',
    myInfo: {},
    adminMetaData: {},
    curUserData: {}
}

// totpVerifyMessage

const tokenDecode = (token) => {
    const tokenDecoded = deCodeToken(token);
    const expTime = new Date(tokenDecoded.exp * 1000);
    if (new Date() > expTime) {
        return null
    }
    return tokenDecoded;
}

const getToken = localStorage.getItem('authToken');
if (getToken) {
    const getInfo = tokenDecode(getToken)
    // console.log(getInfo)
    if (getInfo) {
        authState.myInfo = getInfo;
        authState.authenticate = true;
        authState.loading = false
    }
}


export const authReducer = (state = authState, action) => {
    const { payload, type } = action;
    if(type === REGISTER_FAIL){
        return {
            ...state,
            error: payload.error,
        }
    }
    if ( type === LOGIN_FAIL) {
        return {
            ...state,
            error: payload.error,
            authenticate: false,
            loading: true,
            myInfo: ''
        }
    }
    if (type === REGISTER_SUCCESS || type === USER_LOGIN_SUCCESS) {
        // console.log(payload)
        // const myInfo = tokenDecode(payload.token);

        return {
            ...state,
            successMessage: payload.successMessage,
            error: '',
            authenticate: true,
            loading: false
        }
    }
    if (type === SUCCESS_MESSAGE_CLEAR) {
        return {
            ...state,
            successMessage: ''
        }
    }
    if (type === ADMIN_QRCODE_SUCCESS) {
        return {
            ...state,
            secret: payload.secret,
            qrCode: payload.qrCode,
        }
    }
    if (type === ADMIN_VERIFYQRCODE_SUCCESS) {
        return {
            ...state,
            totpVerifyMessage: payload.totpVerifyMessage
        }
    }
    if (type === ADMIN_VERIFYQRCODE_SUCCESS_CLEAR) {
        return {
            ...state,
            totpVerifyMessage: ''
        }
    }
    if (type === USER_GET_META_SUCCESS) {
        return {
            ...state,
            adminMetaData: payload.adminMetaData
        }
    }
    if(type === USER_LOGIN_VERIFY_SUCCESS){
        return{
            ...state,
            successVerifyMessage: payload.successVerifyMessage
        }
    }
    if(type === LOGIN_VERIFY_FAIL){
        return{
            ...state,
            error: payload.error
        }
    }
    if (type === ERROR_CLEAR) {
        return {
            ...state,
            error: ''
        }
    }
    if(type === ADMIN_DATA_GET_SUCCESS){
        return {
            ...state,
            curUserData: payload.curUserData
        }
    }
    if(type === ADMIN_DATA_GET_FAIL){
        return {
            error: payload.error
        }
    }
    if(type === ADMIN_DATA_UPDATE_SUCCESS){
        return {
            ...state,
            successMessage: payload.successMessage,
        }
    }
    if(type === ADMIN_DATA_UPDATE_FAIL){
        return {
            ...state,
            error: payload.error
        }
    }
    if (type === SIGN_OUT) {
        return {
            ...state,
            loading: true,
            authenticate: false,
            error: '',
            successMessage: '',
            myInfo: ''
        }
    }
    return state;
}